import { addDays, differenceInDays, format, parseISO } from "date-fns";
import { memo, useEffect } from "react";
import {
  AddItemButton,
  DateInput,
  NumberInput,
  SelectArrayInput,
  SimpleFormIterator,
  useArrayInput,
} from "react-admin";
import { AppDataItem } from "../../common/app-data/AppData";
import { useFormContext } from "react-hook-form";
import { Void } from "./Void";
import { ProgramSelector } from "./ProgramSelector";
import { MealSelector } from "./MealSelector";

interface Props {
  programs: AppDataItem[];
  food: AppDataItem[];
  dateFrom?: string;
  dateTo?: string;
  description?: any[];
}

export const DescriptionDay = memo(
  ({ programs, food, dateFrom, dateTo }: Props) => {
    const { append, remove } = useArrayInput();
    const { setValue, watch } = useFormContext();
    const description = watch("description");

    useEffect(() => {
      setValue("undefined", description);
    }, [description]);

    useEffect(() => {
      if (!dateFrom || !dateTo) {
        return;
      }

      const from = parseISO(dateFrom);
      const to = parseISO(dateTo);
      const days = differenceInDays(to, from);

      for (let day = 0; day <= days; day++) {
        const date = format(addDays(from, day), "yyyy-MM-dd");
        if ((description as any[]).some((x) => x.date === date)) {
          continue;
        }

        append({
          date,
          foodIds: [],
          programIds: [],
        });
      }

      let idx = 0;
      for (const existing of description) {
        const difference = differenceInDays(parseISO(existing.date), from);
        if (difference < 0 || difference > days) {
          remove(idx);
        }

        idx++;
      }
    });

    return (
      <SimpleFormIterator
        inline
        removeButton={<Void />}
        addButton={<Void />}
        reOrderButtons={<Void />}
        disableClear
      >
        <DateInput
          source="date"
          label="Date"
          sx={{
            minWidth: "210px",
            paddingLeft: "3%",
            paddingRight: "3%",
            width: "300px",
            paddingTop: "3.5%",
          }}
        />
        <ProgramSelector programs={programs} />
        <MealSelector food={food} />
      </SimpleFormIterator>
    );
  },
  (previous, current) => {
    return (
      previous.dateFrom === current.dateFrom &&
      previous.dateTo === current.dateTo
    );
  }
);
