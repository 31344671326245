import {
  ArrayInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
} from "react-admin";
import { AppDataItem } from "../../common/app-data/AppData";
import { Void } from "./Void";

interface Props {
  programs: AppDataItem[];
  source?: string;
}

export const ProgramSelector = (props: Props) => {
  const { programs, source } = props;
  const sourcePrefix = !!source ? source + "." : "";

  return (
    <ArrayInput source={`${sourcePrefix}programIds`}>
      <SimpleFormIterator reOrderButtons={<Void />}>
        <SelectInput
          source={`programId`}
          label="Program per day"
          choices={programs}
          sx={{ width: "210px" }}
        />
        <NumberInput
          variant="outlined"
          helperText="Persons per program"
          source={`personNumber`}
          defaultValue={0}
          min={0}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
