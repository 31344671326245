import {
  ArrayInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
} from "react-admin";
import { AppDataItem } from "../../common/app-data/AppData";
import { Void } from "./Void";

interface Props {
  food: AppDataItem[];
  source?: string;
}

export const MealSelector = (props: Props) => {
  const { food, source } = props;
  const sourcePrefix = !!source ? source + "." : "";
  return (
    <ArrayInput source={`${sourcePrefix}foodIds`}>
      <SimpleFormIterator reOrderButtons={<Void />}>
        <SelectInput
          source={`foodId`}
          label="Meal"
          choices={food}
          sx={{ width: "210px" }}
        />
        <NumberInput
          variant="outlined"
          source={`personNumber`}
          defaultValue={0}
          min={0}
        />
        <NumberInput
          variant="outlined"
          source={`veganNumber`}
          defaultValue={0}
          min={0}
        />
        <NumberInput
          variant="outlined"
          source={`vegetarianNumber`}
          defaultValue={0}
          min={0}
        />
        <NumberInput
          variant="outlined"
          source={`glutenFreeNumber`}
          defaultValue={0}
          min={0}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
