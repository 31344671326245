import { useEffect, useState } from "react";
import {
  List,
  DateInput,
  Datagrid,
  TextField,
  FunctionField,
} from "react-admin";
import { ReservationDto } from "../reservations/ReservationDto";
import Axios from "axios";
import {
  SumDetails,
  SumDetailsResponse,
  TotalDetails,
  TotalDetailsResponse,
} from "../../common/app-data/AppData";
import SumDetailsTable from "./report-table";
import SumTotalTable from "./report-table-program";

export const ReportsDaily = () => {
  const [sum, setSum] = useState<SumDetails[]>([]);
  const [total, setTotal] = useState<TotalDetails[]>([]);
  const [date, setDate] = useState<string>(new Date().toISOString());

  useEffect(() => {
    const params = {
      date: date.split("T")[0],
    };

    Axios.post<SumDetailsResponse>("api/reservation/report/meals", params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        setSum(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });

    Axios.post<TotalDetailsResponse>(
      "api/reservation/report/programs",
      params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        setTotal(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  const postFilters = [
    <DateInput
      label="Date"
      source="date"
      alwaysOn
      onChange={(event) => setDate(event.target.valueAsDate.toISOString())}
    />,
  ];

  return (
    <>
      <SumDetailsTable details={sum} />
      <SumTotalTable details={total} />

      <List
        filters={postFilters}
        filterDefaultValues={{
          date: new Date().toISOString(),
        }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="Id" />
          <FunctionField<ReservationDto>
            label="Date"
            render={(record) => {
              if (!record) {
                return null;
              }
              const acc = record.description.map((x) => x.date).join("/");
              return acc;
            }}
          />

          <TextField source="personNumber" label="Persons" />
          <TextField source="name" label="Name" />
          <FunctionField<ReservationDto>
            label="Meal"
            render={(record) => {
              if (!record) {
                return null;
              }
              const food = record.description.map((x) => x.foodNames).join("/");
              return food;
            }}
          />
          <FunctionField<ReservationDto>
            label="Programs"
            render={(record) => {
              if (!record) {
                return null;
              }
              const food = record.description
                .map((x) => x.programTitles)
                .join("/");
              return food;
            }}
          />
          <FunctionField<ReservationDto>
            label="Accomodation"
            render={(record) => {
              if (!record) {
                return null;
              }
              const acc = record.accommodations.join("/");
              return acc;
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};
