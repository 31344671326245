import { useContext } from "react";
import {
  TextInput,
  SelectInput,
  DateInput,
  NumberInput,
  SelectArrayInput,
  FormDataConsumer,
} from "react-admin";
import { AppDataContext } from "../../common/app-data/AppDataContext";
import {
  requiredField,
  validateName,
} from "../../validations/reservations/formField";
import { RichTextInput } from "ra-input-rich-text";
import { DescriptionInput } from "./DescriptionInput";

export const ReservationForm = (props: any) => {
  const appData = useContext(AppDataContext);

  const food = appData.food ?? [];
  const accommodation = appData.accommodation ?? [];
  const country = appData.country ?? [];
  const payment = appData.payment ?? [];
  const program = appData.program ?? [];

  return (
    <>
      <TextInput
        variant="outlined"
        source="name"
        validate={validateName}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <TextInput
        variant="outlined"
        source="contact"
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <DateInput
        source="dateFrom"
        validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <NumberInput
        variant="outlined"
        source="personNumber"
        validate={requiredField}
        min={0}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <DateInput
        source="dateTo"
        validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <SelectArrayInput
        source="accommodations"
        label="Acommodation"
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
        choices={accommodation}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <DescriptionInput
            formData={formData}
            source="description"
            programs={program}
            food={food}
          />
        )}
      </FormDataConsumer>
      <SelectInput
        source="payment.id"
        label="Payment"
        defaultValue={0}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
        choices={payment}
      />
      <TextInput
        variant="outlined"
        source="paymentDetails"
        label="Payment details"
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <SelectInput
        source="country.id"
        label="Country"
        defaultValue={0}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
          marginBottom: "0px",
        }}
        choices={country}
      />
      <div
        style={{
          width: "94%",
          paddingLeft: "3%",
          paddingRight: "3%",
          paddingBottom: "15px",
        }}
      >
        <RichTextInput
          label="Detailed description"
          source="desc"
          sx={{ width: "93%" }}
        />
      </div>
    </>
  );
};
