import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SumDetails } from "../../common/app-data/AppData";

interface SumDetailsTableProps {
  details: SumDetails[];
}

export default function SumDetailsTable(props: SumDetailsTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Obrok</TableCell>
            <TableCell align="center">Total</TableCell>
            <TableCell align="center">Vegan</TableCell>
            <TableCell align="center">Vegetarijanac</TableCell>
            <TableCell align="center">Gluten free</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.details.map((row) => (
            <TableRow key={row.name} style={{ height: "38px" }}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.total}</TableCell>
              <TableCell align="center">{row.vegan}</TableCell>
              <TableCell align="center">{row.vegetarian}</TableCell>
              <TableCell align="center">{row.glutenfree}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
